html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* GLOBAL COLORS */
:root{
  --genesis-blue: rgb(19,18,95);
  --genesis-trans-blue: rgba(19,18,95,0.904);
  --genesis-orange: rgb(254, 165, 0);
  --genesis-dark-orange: rgb(255,115,0);
  --genesis-trans-orange: rgba(255,165,0,0.7);
  --genesis-grey: rgb(145, 147, 150);
  --genesis-dark-grey: rgb(184,189,194);
  
}
/* GLOBAL CLASSES */
.my-row {
  display: flex;
  flex-direction: row;
}

.my-col {
  display: flex;
  flex-direction: column;
}

/* spacer for navbar that doesn't take up space */
.hidden-spacer {
  top: -8.5vw;
  position: relative;
}
@media only screen and (max-width:850px) {
  .hidden-spacer{
    top: -18vw;
    position: relative;
  }
}
  
/* spacer for navbar that takes up actual space */
.header-spacer {
  height: 8.5vw;
}
@media only screen and (max-width:850px) {
  .header-spacer {
    height: 18vw;
  }
}
