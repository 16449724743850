/* made this container so that I could have a service div that comes after the call 2 action content */
#c2a-container {
    position: relative;
    width: 100vw;
    height: fit-content;
}
    /* this div contains the image and the c2a button/link */
    #c2a-img-container {
        align-items: center;
        background-image: url(/images/call2actionImages/freeEstimate.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        height:100vh;
        width: 100vw;
    }

        #c2a-img-container a {
            background-color: rgba(255, 165, 0, 0.7);
            border: 0.5vw solid white;
            color: white;
            font-family:'Palanquin Dark';
            margin-top: 25%;
            padding: 3%;
            width: fit-content;
        }

        #c2a-img-container  a:hover {
            background-color: rgb(255, 115, 0);
            text-decoration: none;
        }

            #c2a-text {
                font-size: 3vw;
            }

/* MOBILE STYLING*/
@media only screen and (max-width:850px) {

    #c2a-img-container {
        height:50vh;
        width: 100vw;
    }

        #c2a-img-container a {
            margin-top: 40% !important;
        }

            #c2a-text {
                margin:0;
            }

}
    