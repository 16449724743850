/* container of footer and copyright info */
#footer-component-container {
    height: fit-content;
    position: relative;
    width: 100vw;
}

    /* Footer Layout Container */
    #footer-component-container #footer-container {
        background-color: rgb(219, 224, 230);
        box-shadow: 0vw 0.347vw 0.694vw #5b5d5e;
        flex-wrap: wrap;
        height:95%;
        justify-content: space-evenly;
        position: relative;
        width: 100vw;
    }

        /* Company Logo and Message container */
        #footer-container .footer-box {
            height:100%;
            justify-content: center;
            padding: 2vw;
            position: relative;
            width: fit-content;
        }

            /* logo container */
            .footer-box #footer-logo-container {
                height: 15vw;
                margin: auto;
                width: 100%;
            }

                /* logo image */
                #footer-logo-container #footer-logo {
                    background-image: url('/images/footerImages/Genesis_OG_Logo.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size:contain;
                    height: 100%;
                    width:100%;
                }

            /* company message container */
            .footer-box #footer-company-text-container {
                height: fit-content;
                justify-content: center;
                margin: auto;
                margin-top: 1%;
                width: 25vw;
            }

                /* company message */
                #footer-company-text-container h5 {
                    align-self: center;
                    color: black;
                    font-size: 1.6vw;
                    height:fit-content;
                    text-indent: 2.083vw;
                    width:100%;
                }

            /* Container for footer header and footer */
            .footer-box #form-outer-container {
                align-items: center;
                background-color: rgba(22, 21, 21, 0.178);
                border-radius: 0.256vw;
                height: 100%;
                justify-content: center;
                padding: 2vw;
                position: relative;
                width: 100%;
            }

                /* Footer title */
                #form-outer-container h3 {
                    font-size: 1.9vw;
                    margin-bottom: 1.5vw;
                }

                /* Actual Form Container */
                #form-outer-container #footer-form-container {
                    align-content: center;
                    height: 100%;
                    position: relative;
                    width: 100%;
                }

    #copyright-container { 
        background-color: rgb(145, 147, 150);
        height: 5%;
        justify-content: center;
        position: relative;
        width: 100%;
    }

        #copyright-container p {
            font-size: 1vw;
            height: 100%;
            position: relative;
            margin: auto;
        }

/* MOBILE STYLING */
@media only screen and (max-width: 850px) {

        #footer-container #fbox1 {
            height:100%;
            justify-content: center;
            order: 1;
            position: relative;
            width: 100%;
        }

            #fbox1 #footer-logo-container {
                margin-bottom: 5vw;
                margin-top: 5vw;
                width: 100%;
            }

            #fbox1 #footer-company-text-container {
                margin-bottom: 5vw;
                width: 90%;
            }

                #footer-company-text-container h5 {
                    font-size: 4vw;
                }

        #footer-container #fbox2 {
            box-shadow: 0px 1px 15px rgb(59, 57, 57);
            height: 100%;
            justify-content: center;
            order: 3;
            padding: 2vw;
            position: relative;
            width: fit-content;
            z-index: 2;
        }

            #fbox2 #form-outer-container {
                background-color: rgba(0,0,0,0);
            }

                #form-outer-container  h3 {
                    font-size: 4vw;
                    margin-bottom: 3%;
                }

        #footer-container #fbox3 {
            background-color: rgba(22, 21, 21, 0.178);
            box-shadow: inset 0px 5px 10px rgb(59, 57, 57);
            order: 2;
            padding: 5vw;
            width: 100%;
            z-index: 1;
        }

    #copyright-container { 
        height: fit-content;
        padding-bottom: 1vw;
        padding-top: 1vw;
    } 
    
    #copyright-container p {
        font-size: 3vw !important;
    }
}
