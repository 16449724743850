#service-component-container {
    height: fit-content;
    position: relative;
    width: 100vw;
}
    #services-container {
        background-color: whitesmoke;
        box-shadow: inset 0px -5px 15px #8b8b8b;
        height: fit-content;
        padding-bottom: 8.5vw;
        position: relative;
    }
        #services-container h1 {
            align-self: center;
            border-bottom: double #13125f 0.5vw;
            color:  #13125f;
            font-size: 3vw;
            margin-top: 4vw;
        }

        #service-card-container {
            justify-content: space-evenly;
            flex-wrap: wrap;
        }

@media only screen and (max-width:850px) {
   
    #services-container h1 {
        border-bottom: double #13125f 1.5vw;
        font-size: 10vw;
    }
}
