/* Outer Container */
#photo-gallery-container{
    height:fit-content;
    padding: 1%;
    width: 100vw;
}
    /* Contains carousel image and components */
    #my-carousel{
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        position: relative;
        /* image styling in js file (inline styling) */
    }
        /* image position number container */
        #image-position-number{
            align-self: flex-end;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 1vw;
            display: flex;
            flex-direction: row;
            height: fit-content;
            justify-content: center;
            margin-bottom: 2vh;
            max-height: 20vh;
            max-width: 50vw;
            padding: 1vw;
            width: fit-content;
        }
            /* image position number */
            #image-position-number h6{
                color: white;
                font-size: 1vw;
                height: 2vh;
                margin:auto;
                max-height: 90%;
                text-align: center;
                width: fit-content;
            }
        /* container for carousel arrows */
       #carousel-arrow-container{
            width: fit-content;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        #carousel-arrow-container:hover{
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
            /* wrapper for arrow icon */
            #carousel-arrow-container a{
                color: white;
                font-size: 5vw;
                margin:4vw;
            }

    