#contact-us-container {
    height: 100%;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
}

    #phone-email-address-container {
        position: relative;
    }

        #contact-us-container h3 {
            font-size: 2vw;
            padding-bottom: 2vw;
            text-decoration: underline;
            text-underline-offset: 0.5vw;
            
        }
        .contact-row {
            margin-bottom: 1vw;
            position: relative;
            width: 100%;
            
        }
            .contact-row i {
                align-self: center;
                color: black;
                font-size: 1.25vw;
                font-weight: bold;
                margin: 0;
                padding: 0;
                width: 3vw;
            }

            .contact-row a {
                color:black;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-size: 1.25vw;
                font-weight: 500;
            }

    .hours-container {
        position: relative;
    }

        .contact-row p {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 1.25vw;
            font-weight: 600;
            margin-bottom: 0%;
        }  

/* MOBILE STYLING */
@media only screen and (max-width: 850px) {

    #contact-us-container h3 {
        align-self: center;
        font-size: 4vw;
    }
        .contact-row p,.contact-row a,.contact-row i {
            font-size: 3vw;
        }

        .contact-row i {
            width: 5vw;
        }
}