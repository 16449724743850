.carousel-container {
    height: 85vh;
    position: relative;
    width: 100vw;
}

    #hero-carousel {
        height: 100%;
        position:relative;
        width: 100%;
    }
        /* Contains carousel slides */
        .carousel-inner {
            height:100%;
            position: relative;
            width: 100%;
        }
            /* contains carousel image and caption container*/
            .carousel-item {
                height:100%;
                position: relative;
                width: 100%;
            }

                .carousel-item img { 
                    height:100%;
                    position: relative;
                    width: 100%;
                }
                /* caption containers*/
                #carousel-cap1, #carousel-cap2, #carousel-cap3 {
                    align-items: center;
                    bottom:0%;
                    display: flex;
                    flex-direction: column;
                    height: 30%;
                    left: 0%;
                    padding: 0%;
                    width: 100%;
                    z-index: 3;
                }
                    
                    .caption-text-container {
                        align-items: center;
                        animation-delay: 0ms;
                        animation-duration: 1000ms;
                        animation-fill-mode: forwards;
                        animation-name: fadeIn;
                        animation-timing-function: ease-in;
                        background-color:rgba(19, 18, 95, 0.904);
                        border: 0.5vw solid white;
                        display: flex;
                        flex-direction: column;
                        height: 22%;
                        justify-content: center;
                        padding: 4.5%;
                        width: fit-content;
                        z-index: 3;
                    }
                    @keyframes fadeIn {
                        from { 
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    .caption-text-container:hover {
                        background-color: rgb(255, 115, 0);
                        cursor: pointer;
                    }

                        .carousel-caption-link {
                            color: white;
                        }

                        .carousel-caption-link:link {
                            color:white;
                            text-decoration: none;
                        }

                        .carousel-caption-link:hover {
                            color:white;
                            text-decoration: none;
                        }

                            .carousel-caption-link h1 {
                                font-family: serif;
                                font-size: 2vw;
                                font-weight: bolder;
                                height: fit-content;
                                justify-self: center;
                                margin-top: 20%;
                                width: 100%;
                            }

                            .carousel-caption-link div {
                                background-color: white;
                                margin: 2% auto 2% auto;
                                min-height: 0.2vw;
                                width: 70%;
                            }

                            .carousel-caption-link p {
                                font-family:Helvetica, sans-serif;
                                font-size: 1.3vw;
                                font-weight: bold;
                                height: fit-content;
                                justify-self: center;
                                margin-bottom: 20%;
                                margin-left: auto;
                                margin-right: auto;
                                width: fit-content;
                            }

        .carousel-control-prev {
            height: 100%;
            z-index: 3;
        }

        .carousel-control-prev:hover {
            background-color: rgba(0,0,0,0.4);
        }

        .carousel-control-next {
            height: 100%;
            z-index: 3;
        }

        .carousel-control-next:hover {
            background-color: rgba(0,0,0,0.4);
        }

            #carousel-prev-arrow, #carousel-next-arrow {
                color: white;
                font-size: 8vw;
            }

/* MOBILE STYLING */
@media only screen and (max-width: 850px) {
    .carousel-container {
        height: 35vh;
    }

                        .caption-text-container {
                            padding-bottom: 6vw;
                            padding-top: 6vw;
                        }

                                .carousel-caption-link p {
                                    font-size: 1.8vw !important;
                                }

                                .carousel-caption-link h1 {
                                    font-size: 2.8vw !important;
                                    margin-bottom: 0%;
                                }  
}

