
#quote-container {
    background-color: transparent;
    height: fit-content;
    justify-content: center;
    position: relative;
    width: 100vw;
}

    blockquote {
        background-color: #E8E8E8;
        border-radius: 0.347vw;
        font-size: 1.677vw;
        margin-top: 2vw;
        padding: 1.736vw;
        position: relative;
        width: 100%;
    }

    blockquote:before {
        color: orange;
        content: '\f10d';
        font-family: 'Fontawesome';
        font-size: 2.3vw;
        left: -1.389vw;
        position: absolute;
        top:-1.389vw;
    }

    blockquote:after {
        bottom: -1.389vw;
        color: orange;
        content: '\f10e';
        font-family: 'Fontawesome';
        font-size: 2.3vw;
        position: absolute;
        right: -1.389vw;
    }
        blockquote p {
            display: inline;
            font-style: italic;
            text-align: center;
        }

            #author-container p {
                align-self: center;
                color: orange;
                display: block;
                font-style: normal;
                font-weight: bold;
                margin-top: 1.389vw;
                max-width: fit-content;
            }

            #authors-name {
                font-size: 2vw;
            }
            
            #authors-loc {
                font-size: 1.5vw;
            }


@media only screen and (max-width: 850px) {

    blockquote p {
        font-size: 3vw;
    }

    #authors-name {
        font-size: 3.5vw;
        margin-bottom: 0%;
    }

    #authors-loc {
        font-size: 2vw;
    }
}