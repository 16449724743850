/* FORM COMPONENT CONTAINER */
.form-container {
    height: 100%;
    overflow-x: hidden;
    padding: 1%;
    position: relative;
    width: 100%;
}

    /* ACTUAL FORM CONTAINER */
    .basic-form {
        position: relative;
        width: 100%;
    }

        /* FIRST&LAST && EMAIL&PHONE CONTAINER */
        .form-row {
            align-self: center;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            width: 100%;
        }

            /* LABEL & INPUT CONTAINER */
            .form-col {
                justify-content: center;
                position: relative;
                width: 45%;
            }

        #form-button {
            align-self: center;
            position:relative;
            background-color: rgb(254, 165, 0);
            width: fit-content;
        }

        #form-button:hover {
            background-color:  rgb(255, 115, 0);
            color: white;
        }

        /* TEXT AREA */
        #message {
            height: 10vh;
            position: relative;
        }

        /* ALL LABELS*/
        .basic-form label {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 1.25vw;
            font-weight: 500;
        }

        /* INPUT AND TEXT AREA */
        .basic-form input, #message {
            margin-bottom: 2vw;
        }

        #sent-message {
            align-self: center;
            color: rgb(0, 172, 0);
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: 1.25vw;
            font-weight: 800;
            margin-top: 2vw;
        }

        /* SENT MESSAGE ANIMATION */
        .Show {
            animation-duration: 500ms;
            animation-name: appear;
            animation-timing-function: linear;
        }
        @keyframes appear{
            from{
                opacity: 0;
            }
            to{
                opacity: 1;
            }
        }
        .Hide {
            animation-duration: 500ms;
            animation-fill-mode: forwards;
            animation-name: disappear;
            animation-timing-function: linear;
        }
        @keyframes disappear{
            from{
                opacity: 1;
            }
            to{
                opacity: 0;
            }
        }

/* MOBILE STYLING */
@media only screen and (max-width: 850px) {

    .form-col {
        width: 100%;
    }

    #sent-message {
        font-size:3vw;
    }

    .basic-form label {
        font-size: 3vw;
    }

    #form-button {
        font-size: 3vw;
    }
}
