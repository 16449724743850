/* div that contains image of northern virginia*/
#contact-outer-container {
    background-image: url(/images/contactImages/dmvMapBlue.png);
    background-position: center;
    background-size: cover;
    height: fit-content;
    overflow: hidden;
    position: relative;
    width: 100vw;
}
    /* layout container */
    #contact-container {
        flex-wrap: wrap-reverse;
        height: fit-content;
        justify-content: space-between;
        padding: 5vw;
        position: relative;
        width: 100%;
    }
        /* Contains the leave us a message form*/
        #contact-container #form-wrapper {
            align-content: center;
            align-self: center;
            background-color: rgba(159, 221, 240, 0.89);
            border-radius: 0.694vw;
            border-style: double;
            border-width: 0.55vw;
            height: fit-content;
            padding: 2vw;
            position: relative;
            width: 55%;
        }
            /* Leave Us A Message Header */
            #form-wrapper h2 {
                align-self: center;
                border-bottom: 0.208vw solid black;
                margin-bottom: 3vw;
            }

        /* Outermost container containing contact us and hours information*/
        #contact-container #contact-us-wrapper {
            align-self: center;
            background-color: rgba(159, 221, 240, 0.89);
            border-radius: 0.694vw;
            border-style: double;
            border-width: 0.55vw;
            height: fit-content;
            justify-content: center;
            padding: 2vw;
            position:relative;
            width: 35vw;
        }

/* MOBILE STYLING */
@media only screen and (max-width: 850px) {
    
    #contact-container {
        justify-content: center;
    }

        #contact-container #form-wrapper {
            border-width: 0.85vw;
            width: 90%;
        }
            /* Leave Us A Message Header */
            #form-wrapper h2 {
                align-self: center;
                border-bottom: 0.508vw solid black;
                font-size: 5vw;
                margin-bottom: 6vw;
                margin-top: 3vw;
            } 
        /* Outermost container containing contact us and hours information*/
        #contact-container #contact-us-wrapper {
            border-width: 0.85vw;
            height: fit-content;
            margin-bottom: 5vw;
            padding-right: 2vw;
            position: relative;
            width: fit-content;
        }
}
