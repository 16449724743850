#contact-banner {
    align-items: center;
    background-color: #13125f;
    font-family: Arial, Helvetica, sans-serif;
    height: 2.5vw;
    justify-content:center;
    position: fixed;
    width: 100vw;
    z-index: 4;
}

    #contact-banner .contact-link {
        align-items: center;
        color:white;
        font-size: 1.5vw;
        height: 100%;
        justify-content: space-evenly;
        text-decoration: none;

    }

        #contact-banner p {
            align-self: center;
            height: 100%;
            margin: 0%;
            padding-left: 2vw;
            padding-right: 2vw;
            text-align: center;
        }

        #contact-spacer {
            padding-left: 0vw !important;
        }

@media only screen and (max-width:850px) {

    #contact-banner {
        height: 8vw;
    }
        #contact-banner p {
            font-size: 2.4vw;
            height: fit-content !important;
            position: relative;
        }
        #contact-banner i {
            font-size: 2vw;
        }
}


