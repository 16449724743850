#service-page-wrapper {
    background-color: white;
    height: 100%;
    overflow:hidden;
    position: relative;
    width: 100%;
}

    #service-page-container {
        height: fit-content;
        position: relative;
        width: 100vw;
    }

       #service-page-content-container {
            background-color:white;
            height: fit-content;
            position: relative;
        }

            #service-page-image-container {
                height: fit-content;
                justify-content: space-evenly;
                overflow-y: hidden;
                padding: 0% 0% 0% 0%; 
                position: relative;
                width: 100%;
            }

                #service-page-image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 50vh;
                    width: 100vw;
                }

                    #service-page-image h1 {
                        background-color:rgba(19, 18, 95, 0.904);
                        border: 0.6vw solid white;
                        border-radius: 0.5vw;
                        color: white;
                        font-size: 5vw;
                        font-weight: 900;
                        justify-content: center;
                        margin: auto;
                        padding: 2%;
                        text-align: center;
                        width: fit-content;
                    }

            #service-page-text-container {
                padding: 4% 4% 1% 4%;
            }
                #service-page-text-container h1{
                    color: maroon;
                    font-size: 3vw;
                    font-weight: bold;
                    padding-bottom: 2%;
                }
                #service-page-text-container p{
                    font-size: 2vw;
                    text-indent: 3%;
                }
                    #service-page-text-container p a{
                        padding-left: 0.5%;
                    }
            #service-page-gallery-container {
                height: fit-content;
                width: 100vw;
            }
                #service-page-gallery-container h1 {
                    color: maroon;
                    font-size: 3vw;
                    font-weight: bold;
                    padding-bottom: 2%;
                    padding-left: 4%
                }

@media only screen and (max-width:850px) {

    #service-page-text-container h1 {
        font-size: 5vw;
    }

    #service-page-text-container p {
        font-size: 4vw;
    }

    #service-page-gallery-container h1 {
        font-size: 5vw !important;
    }

    [data-aos-delay="500"] {
        transition-delay: 0ms;
    }
}
