#testimonial-container {
    background-image: url("/images/testimonialImages/abstractOrangeWaves.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    justify-content: center;
    padding: 4vw;
    width: 100vw;
}

    #testimonial-container h1 {
        align-self: center;
        border-bottom: double #13125f 0.5vw;
        color:  #13125f;
        font-size: 3vw;
    }

    /* Testimonial content */
    #quote-box-container {
        background-color: transparent;
        height: fit-content;
        justify-content: center;
        margin: auto;
        width: 100%;
    }
        .testimonial-button {
            background-color: transparent;
            border: none;
            outline: none;
            width: 35%;
        }
            .testimonial-button i {
                color:  #13125f;
                font-size: 7vw;
                margin: auto;
            }

            .testimonial-button i:hover {
                color: orange;
            }

            .testimonial-button i:active {
                color: #13125f;
            }
        

    /* Carousel Dots */
    #position-dots-wrapper {
        height: fit-content;
        justify-content: center;
        width: 92vw;
    }
        #position-dots-container {
            justify-content: space-around;
            top: 0vw;
            width: 10%; 
        }
            #position-dots-container .dots {
                background-color: grey;
                border-radius: 0.347vw;
                height: 0.694vw;
                width: 0.694vw;
            }

            #position-dots-container .active-dot {
                background-color: orange;
            }

/* CSSTransition Stuff */

.fade-appear {
    opacity: 0;
    z-index: 1;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
}

.fade-enter {
    opacity: 0;
    right: -14.722vw;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1; 
    transition: opacity 300ms linear;
}

.fade-exit {
    left: -14.722px;
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0; 
    transition: opacity 300ms linear;
}
.fade-exit-done {
    opacity: 0;
}

/* Mobile Styling */
@media only screen and (max-width:850px) {

    #testimonial-container h1{
        border-bottom: double #13125f 0.5vw;
        font-size: 5vw;
    }
}