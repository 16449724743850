#gallery-page-container {
    background-color: rgb(250, 229, 192);
    height: fit-content;
    overflow-x: hidden;
    position: relative;
    width: 100vw;
}

    /* Modal element has inline styling */
        /* Elements inside modals are styled here*/
        #modal-content-container {
            background-color: black;
            height: 100%;
            justify-content: center;
            position: relative;
            width: 100%;
        }

            /* exit button styling */
            #modal-exit-button {
                background-color: transparent;
                border-style: hidden;
                color: white; 
                font-size: 3vw;
                justify-content: center;
                left: 1vw;
                min-width: 1vw;
                position: absolute;
                top: 1vw;
                z-index: 3;
            }

            #modal-exit-button:active {
                outline: none;
            }

                /* styling for X inside of exit button */
                #modal-exit-button i {
                    height: 100%;
                    width: 100%;
                }

            /* left arrow button styling*/
            #modal-left-arrow {
               
                background-color: transparent;
                border-style: hidden;
                color: white;
                font-size: 4vw;
                height: 100%;
                left: 0%;
                padding: 2vw; 
                position: absolute;
                text-align: center;
                top: 0%;
                width: 20%;
                z-index: 2;
            }
            #modal-left-arrow:focus {
                outline: none;
            }
            /* right arrow button styling*/
            #modal-right-arrow {
                background-color: transparent;
                border-style: hidden;
                color: white;
                font-size: 4vw;
                height: 100%;
                padding: 2vw;            
                position: absolute;
                right: 0%;
                text-align: center;
                top: 0%;
                width: 20%;
                z-index: 2;
            }
            #modal-right-arrow:focus {
                outline: none;
            }

            /* styling for p element which shows image ratio ex: (1/100) */
            #image-position-ratio {
                align-self: flex-end;
                background-color: rgba(0,0,0,0.75);
                border-radius: 1vw;
                color: white;
                height: fit-content;
                padding: 1vw;
                text-align: center;
                width: fit-content;
                z-index: 3;
            }
            /* styling for the div that displays modal images*/
            #modal-image-container {

                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 100%;
                left:0%;
                position: absolute;
                top: 0%;
                width: 100%;
                z-index: 1;
                
            }
    /* This contains the actual page components like navbar, contact banner, gallery, footer....etc */
    #gallery-page-content {
        height: fit-content;
        overflow-x: hidden;
        position: relative;
        width: 100vw;
    }
        /* styling for header image container*/
        #gallery-header-container {
            height: fit-content;
            justify-content: space-evenly;
            position: relative;
            width: 100vw;
        }
            /* styling for header image */
            #gallery-header-image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 50vh;
                width: 100vw;
            }

                /* Styling Header Image Text: Project Gallery */
                #gallery-header-image h1 {
                    background-color:rgba(19, 18, 95, 0.904);
                    border: 0.6vw solid white;
                    border-radius: 0.5vw;
                    color: white;
                    font-size: 5vw;
                    font-weight: 900;
                    justify-content: center;
                    margin: auto;
                    padding: 2%;
                    text-align: center;
                    width: fit-content;
                }
        /* container for photo gallery component*/
        #my-photo-gallery-wrapper {
            height: fit-content;
            overflow-x: hidden;
            position: relative;
            width: 100vw;
        }

/* styling for mobile */
@media only screen and (max-width: 850px) {
    #modal-exit-button{
        font-size: 6vw;
    }
    #gallery-header-image-container .galleryHeaderImage{
        height: 40vw;
    }
}