.img-card-container{
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    cursor: pointer;
    height: 300px;
    margin-top: 60px;
    transition: all 1s;
    width: 400px;
}

[data-aos="zoom-in"].img-card-container:hover{
    transform: scale(1.2);
}

#service-btn{
    background-color: rgba(255, 165, 0, 0.7);
    margin: auto;
    margin-bottom: 10%;
}

#service-btn:hover{
    background-color: rgb(255, 115, 0);
    color: white;;
}

@media only screen and (max-width:850px) {
    
    .img-card-container{
        background-position: center;
        background-size: cover;
        border-radius: 5px;
        cursor: pointer;
        height: 250px;
        margin-top: 60px;
        transition: all 1s;
        width: 350px;
    }
}
