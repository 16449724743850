.navbar-container {
    background-color: white;
    box-shadow: inset 0px -0.40vw 0.40vw #8b8b8b;
    height:6vw;
    justify-content: flex-start;
    margin-top: 2.5vw;
    padding-bottom: 1vw;
    padding-top: 0.75vw;
    position: fixed;
    width: 100vw;
    z-index: 4;
}

    #genesis-nav {
        
        height: 100%;
        justify-content: space-around;
        width: 100%;
    }

        /* LOGO */
        .logo-div-container {
            height: 100%;
            justify-content: center;
            position: relative;
            width: fit-content;
        }

            .logo-div-container a {
                height: 100%;
                justify-content: center;
                position: relative;
                width: fit-content;
            }

                .logo-img {   
                    background-image: url(/images/navbarImages/logo_80x240.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 100%;
                    justify-self: center;
                    width: 240px;
                }
        /* LOGO END */

        /* LINKS */
        .nav-links {
            height: 100%;
            justify-content: space-between;
            justify-items: center;
            margin-bottom: 0%;
            padding-left: 0%;
            position: relative;
            width: 67%;
        }

            .nav-links li {
                justify-content: center;
                list-style: none;
                position: relative;
            }

                .nav-links a {
                    color: #13125f;
                    font-size: 2.5vw;
                    text-decoration: none;
                }

                .nav-links a:hover {
                    border-bottom: 0.35vh solid #13125f;
                    color: #13125f;
                }
        /* LINKS END */


@media only screen and (max-width:850px) {

    .navbar-container{ 
        height:10vw;
        margin-top: 8vw;
        padding-bottom: 1.5vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
    }
    .logo-div-container{
        width: 25vw;
    }
}