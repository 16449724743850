/* Outermost container used to order hidden spacer at the top*/
#about-container {
    background-color: white;
    height: fit-content;
    position: relative;
    width: 100vw;
}
/* about-us content layout container */
#about-us-container {
    flex-wrap: wrap;
    height: fit-content;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
}
    /* Container for about us header and paragraphs */
    #about-text-container {
        height: fit-content;
        justify-content: space-evenly;
        padding: 4vw;
        width: 50%;
    }
        /* About us header*/
        #about-text-container h1 {
            align-self: center;
            border-bottom: double var(--genesis-blue) 0.5vw;
            color: var(--genesis-blue);
            font-size: 3vw;
            margin-bottom: 3vw;
            width: fit-content;
        }
        /* About us paragraphs */
        #about-text-container p {
            font-size: 1.3vw;
            font-weight: 500;
            text-indent: 2.7vw;
        }
    /* Container for about us image and the mobile header */
    #about-img-container {
        height: 100%;
        justify-content: center;
        padding: 6vw;
        width: 50%;
    }   
        /* about us image */
        #about-img-container img {
            border-radius: 0.8vw;
            height: 100%;
            width: 100%;
        }
        /* mobile about us header */
        #about-img-container h1 {
            display: none;
        }

/* MOBILE STYLING */
@media only screen and (max-width:850px) {
    /* Contains about us header and paragraphs */
    #about-text-container {
        order: 2;
        width: 100%;
    }
        /* About us desktop header */
        #about-text-container h1 {
            display: none;
        }
        /* Abouts us paragraphs */
        #about-text-container p {
            font-size: 3vw !important;
        }
    /* contains about us image and mobile about us header */
    #about-img-container {
        order: 1;
        width: 100vw !important;
    }
        /* mobile about us header */
        #about-img-container h1 {
            align-self: center;
            border-bottom: double var(--genesis-blue) 1.5vw;
            color: var(--genesis-blue);
            display: inline !important;
            font-size: 10vw;
            margin-bottom: 10vw;
            width: fit-content;
        }
}